<template>
  <v-container fluid class="payment-completed" v-if="!loading">
    <CategoryTitle :category="category" />

    <v-row class="mt-7">
      <v-col cols="12" md="8" class="order-info">
        <!-- order failed -->
        <div class="payment-failed" v-if="order && paymentFailed">
          <span
            class="order-number d-block mb-3"
            v-html="$t('paymentCompleted.failed', [order.orderId])"
          ></span>
          <v-alert type="error" outlined class="font-weight-bold default--text">
            {{ $t("paymentCompleted.failedDesc1") }}
          </v-alert>
        </div>

        <!-- order success -->
        <div v-else class="payment-success">
          <span
            class="order-number d-block mb-3"
            v-html="$t('paymentCompleted.orderNumber', [order.orderId])"
          ></span>
          <v-alert
            v-if="order.orderStatusId == 3"
            type="success"
            outlined
            class="font-weight-bold default--text mb-4"
          >
            {{ $t("paymentCompleted.success") }}
          </v-alert>

          <span
            class="d-block "
            v-if="
              order.orderStatusId == 3 &&
                order.deliveryAddress.deliveryServiceId !== 4
            "
            v-html="$t('paymentCompleted.successNotRapid')"
          ></span>

          <span
            class="d-block "
            v-if="
              order.orderStatusId == 3 &&
                order.deliveryAddress.deliveryServiceId == 4
            "
            v-html="$t('paymentCompleted.successRapid')"
          ></span>

          <span
            class="d-block "
            v-if="order.orderStatusId == 2"
            v-html="
              $t('paymentCompleted.orderNotConfirmed', {
                orderId: order.orderId
              })
            "
          ></span>

          <span
            v-if="order.orderStatusId == 1 && order.paymentTypeId == 1"
            class="d-block "
            v-html="$t('paymentCompleted.orderNotPayed')"
          ></span>
        </div>

        <PaymentTypeList
          v-if="showPayment"
          mode="order"
          :order-id="order.orderId"
          :options="paymentTypeListOptions"
        />

        <div
          class="btn-actions d-flex flex-column flex-sm-row justify-space-between mt-8"
        >
          <v-btn
            large
            depressed
            color="primary"
            outlined
            :block="$vuetify.breakpoint.xs"
            @click="goHome"
            >{{ $t("paymentCompleted.goToHome") }}</v-btn
          >

          <v-btn
            v-if="!showPayment && paymentFailed && order.isPayable"
            large
            depressed
            color="primary"
            :block="$vuetify.breakpoint.xs"
            @click="toggleShowPayment"
          >
            {{ $t("paymentCompleted.retry") }}
          </v-btn>

          <v-btn
            v-if="order && !paymentFailed"
            v-bind:to="'/profile/orders/' + order.orderId"
            large
            depressed
            color="primary"
            :block="$vuetify.breakpoint.xs"
          >
            {{ $t("paymentCompleted.goToDetail") }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <CartInfoServiceCard
          :shippingAddress="order.shippingAddress"
          class="h-auto mb-5"
        />
        <CartInfoAddressCard
          :shippingAddress="order.shippingAddress"
          class="h-auto mb-5"
        />
        <CartInfoTimeslotCard
          :shippingAddress="order.shippingAddress"
          :timeslot="order.timeslot"
          class="h-auto"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.payment-completed {
  .order-number {
    font-size: 20px;
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard.vue";

import categoryMixins from "~/mixins/category";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  components: {
    CategoryTitle,
    PaymentTypeList,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CartInfoServiceCard
  },
  data() {
    return {
      loading: true,
      order: null,
      showPayment: false,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "default--text text-uppercase payment-type-list-title",
        prevButton: {
          type: "link-button",
          label: "payment.backToCheckout",
          class: "text-none d-none",
          options: {
            icon: "$prev",
            iconClass: "primary white--text rounded-lg mr-3",
            textClass: "primary--text text-caption font-weight-bold"
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics"
    }),
    paymentFailed() {
      return this.order?.orderStatusId == 2 || this.order?.orderStatusId == 11;
    },
    detailDate() {
      return this.$dayjs(this.order.timeslot.date).format("DD MMMM");
    },
    detailTimeslot() {
      return `dalle ore ${this.$dayjs(this.order.timeslot.beginTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm")} alle ore
        ${this.$dayjs(this.order.timeslot.endTime, [
          "h:mm:ss A",
          "H:mm"
        ]).format("HH:mm")}`;
    },
    easyDrive() {
      return (
        this.order.warehouse.params != undefined &&
        this.order.warehouse.params.EASYDRIVE === "1"
      );
    }
  },
  methods: {
    ...mapActions({
      addOrderToAnalytics: "app/addOrderSentToAnalytics"
    }),
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    },
    async fetchOrder(orderId) {
      let _this = this;
      _this.order = await OrderService.getOrder(orderId);
      if (_this.order.orderStatusId == 3) {
        if (!this.isOrderSentToAnalytics(orderId)) {
          try {
            AnalyticsService.purchase(_this.order);
            this.addOrderToAnalytics(orderId);
          } catch (e) {
            console.log(e);
          }
        }
      }
      _this.loading = false;
    },
    goHome() {
      this.$router.push({ name: "Home" });
    }
  },
  mounted() {
    this.fetchOrder(this.$route.params.orderId);
    global.EventBus.$on("pay-response-ok", order => {
      this.showPayment = false;
      this.fetchOrder(order.orderId);
    });
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  }
};
</script>
