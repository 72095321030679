var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('v-container',{staticClass:"payment-completed",attrs:{"fluid":""}},[_c('CategoryTitle',{attrs:{"category":_vm.category}}),_c('v-row',{staticClass:"mt-7"},[_c('v-col',{staticClass:"order-info",attrs:{"cols":"12","md":"8"}},[(_vm.order && _vm.paymentFailed)?_c('div',{staticClass:"payment-failed"},[_c('span',{staticClass:"order-number d-block mb-3",domProps:{"innerHTML":_vm._s(_vm.$t('paymentCompleted.failed', [_vm.order.orderId]))}}),_c('v-alert',{staticClass:"font-weight-bold default--text",attrs:{"type":"error","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.failedDesc1"))+" ")])],1):_c('div',{staticClass:"payment-success"},[_c('span',{staticClass:"order-number d-block mb-3",domProps:{"innerHTML":_vm._s(_vm.$t('paymentCompleted.orderNumber', [_vm.order.orderId]))}}),(_vm.order.orderStatusId == 3)?_c('v-alert',{staticClass:"font-weight-bold default--text mb-4",attrs:{"type":"success","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.success"))+" ")]):_vm._e(),(
            _vm.order.orderStatusId == 3 &&
              _vm.order.deliveryAddress.deliveryServiceId !== 4
          )?_c('span',{staticClass:"d-block",domProps:{"innerHTML":_vm._s(_vm.$t('paymentCompleted.successNotRapid'))}}):_vm._e(),(
            _vm.order.orderStatusId == 3 &&
              _vm.order.deliveryAddress.deliveryServiceId == 4
          )?_c('span',{staticClass:"d-block",domProps:{"innerHTML":_vm._s(_vm.$t('paymentCompleted.successRapid'))}}):_vm._e(),(_vm.order.orderStatusId == 2)?_c('span',{staticClass:"d-block",domProps:{"innerHTML":_vm._s(
            _vm.$t('paymentCompleted.orderNotConfirmed', {
              orderId: _vm.order.orderId
            })
          )}}):_vm._e(),(_vm.order.orderStatusId == 1 && _vm.order.paymentTypeId == 1)?_c('span',{staticClass:"d-block",domProps:{"innerHTML":_vm._s(_vm.$t('paymentCompleted.orderNotPayed'))}}):_vm._e()],1),(_vm.showPayment)?_c('PaymentTypeList',{attrs:{"mode":"order","order-id":_vm.order.orderId,"options":_vm.paymentTypeListOptions}}):_vm._e(),_c('div',{staticClass:"btn-actions d-flex flex-column flex-sm-row justify-space-between mt-8"},[_c('v-btn',{attrs:{"large":"","depressed":"","color":"primary","outlined":"","block":_vm.$vuetify.breakpoint.xs},on:{"click":_vm.goHome}},[_vm._v(_vm._s(_vm.$t("paymentCompleted.goToHome")))]),(!_vm.showPayment && _vm.paymentFailed && _vm.order.isPayable)?_c('v-btn',{attrs:{"large":"","depressed":"","color":"primary","block":_vm.$vuetify.breakpoint.xs},on:{"click":_vm.toggleShowPayment}},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.retry"))+" ")]):_vm._e(),(_vm.order && !_vm.paymentFailed)?_c('v-btn',{attrs:{"to":'/profile/orders/' + _vm.order.orderId,"large":"","depressed":"","color":"primary","block":_vm.$vuetify.breakpoint.xs}},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.goToDetail"))+" ")]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('CartInfoServiceCard',{staticClass:"h-auto mb-5",attrs:{"shippingAddress":_vm.order.shippingAddress}}),_c('CartInfoAddressCard',{staticClass:"h-auto mb-5",attrs:{"shippingAddress":_vm.order.shippingAddress}}),_c('CartInfoTimeslotCard',{staticClass:"h-auto",attrs:{"shippingAddress":_vm.order.shippingAddress,"timeslot":_vm.order.timeslot}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }